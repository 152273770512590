/** Need import image here or could not be linked after parcel build */
import greenIcon from "./images/green_tomato.ico";
import readIcon from "./images/red_tomato.ico";
import bellSound from "./audio/one_bell.mp3";
const soundEffect = new Audio(bellSound);

/** Represents a timer that can count down. */
function CountdownTimer(seconds, tickRate) {
  this.seconds = seconds || 25 * 60;
  this.tickRate = tickRate || 500; // Milliseconds
  this.tickFunctions = [];
  this.isRunning = false;
  this.remaining = this.seconds;

  /** CountdownTimer starts ticking down and executes all tick
        functions once per tick. */
  this.start = function () {
    if (this.isRunning) {
      return;
    }

    this.isRunning = true;
    document.getElementById("btn_ctrl_icon").className =
      "glyphicon glyphicon-pause";

    // Set variables related to when this timer started
    var startTime = Date.now(),
      thisTimer = this;

    // Tick until complete or interrupted
    (function tick() {
      let secondsSinceStart = ((Date.now() - startTime) / 1000) | 0;
      var secondsRemaining = thisTimer.remaining - secondsSinceStart;

      // Check if timer has been paused by user
      if (thisTimer.isRunning === false) {
        thisTimer.remaining = secondsRemaining;
      } else {
        if (secondsRemaining > 0) {
          // Execute another tick in tickRate milliseconds
          setTimeout(tick, thisTimer.tickRate);
        } else {
          // Stop this timer
          thisTimer.remaining = 0;
          //thisTimer.isRunning = false;
          thisTimer.pause();

          showNotification(0);
          changeFavicon("green");
          // Alert user that time is up
          playAlarm();
        }

        var timeRemaining = parseSeconds(secondsRemaining);

        // Execute each tickFunction in the list with thisTimer
        // as an argument
        thisTimer.tickFunctions.forEach(function (tickFunction) {
          tickFunction.call(this, timeRemaining.minutes, timeRemaining.seconds);
        }, thisTimer);
      }
    })();
  };

  /** Pause the timer. */
  this.pause = function () {
    this.isRunning = false;
    document.getElementById("btn_ctrl_icon").className =
      "glyphicon glyphicon-play";
  };

  /** Pause the timer and reset to its original time. */
  this.reset = function (seconds) {
    this.isRunning = false;
    this.seconds = seconds;
    this.remaining = seconds;
  };

  /** Add a function to the timer's tickFunctions. */
  this.onTick = function (tickFunction) {
    if (typeof tickFunction === "function") {
      this.tickFunctions.push(tickFunction);
    }
  };

  /** Control play or pause */
  this.control = () => {
    if (this.isRunning) {
      console.log("pause");
      this.pause();
    } else {
      console.log("play");
      this.start();
    }
  };
}

/** Return minutes and seconds from seconds. */
function parseSeconds(seconds) {
  return {
    minutes: (seconds / 60) | 0,
    seconds: seconds % 60 | 0,
  };
}

/** Play the selected alarm at selected volume. */
function playAlarm() {
  try{
    //ios safari could not play due to the it must initiate by user itself
    soundEffect.play();
  }catch(err){
    console.log("PlayAlarm", err)
  }
}

/** Change the color of the favicon. */
function changeFavicon(color) {
  let head = document.head || document.getElementsByTagName("head")[0];

  let newFavicon = document.createElement("link"),
    oldFavicon = document.getElementById("dynamic-favicon");
  newFavicon.id = "dynamic-favicon";
  newFavicon.type = "image/ico";
  newFavicon.rel = "icon";
  newFavicon.href = color == "green" ? greenIcon : readIcon;

  if (oldFavicon) {
    head.removeChild(oldFavicon);
  }
  head.appendChild(newFavicon);
}

/** Notification when timer start/expire */
function showNotification(event) {
  //event = 0: timer expired
  //event = 1: timer start
  try {
    var title = event == 0 ? `Time's up!` : `Start!`;
    var notification = new Notification(title, {
      icon: event == 0 ? greenIcon : readIcon,
      body: `${title} - GYZLAB`,
    });
    notification.onclick = function () {
      window.focus();
    };
    console.log("notificaiton", title);
  } catch (err) {
    console.log("notificaiton", err);
    return;
  }
}

/** Window onload functions. */
window.onload = function () {
  var timerDisplay = document.getElementById("timer"),
    customTimeInput = document.getElementById("ipt_custom"),
    timer = new CountdownTimer(),
    timeObj = parseSeconds(25 * 60);

  /** Ask for notification permission */
  try {
    if (Notification.permission !== "granted") {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          console.log("Notification is permitted!");
        } else {
          alert("Please allow notification for better UX!");
        }
      });
    }
  } catch (err) {
    console.log("Notification", err);
  }
  /** Set the time on the main clock display and
        set the time remaining section in the title. */
  function setTimeOnAllDisplays(minutes, seconds) {
    let clockHours;
    let clockMinutes;
    let clockSeconds;
    let hours;

    if (minutes >= 60) {
      // Add an hours section to all displays
      hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      clockHours = hours + ":";
    } else {
      clockHours = "";
    }

    clockMinutes = minutes < 10 ? "0" + minutes : minutes;
    clockMinutes += ":";
    clockSeconds = seconds < 10 ? "0" + seconds : seconds;

    timerDisplay.textContent = clockHours + clockMinutes + clockSeconds;
    document.title = `${clockHours}${clockMinutes}${clockSeconds} - GYZLAB`;
  }

  /** Revert the favicon to red, delete the old timer
        object, and start a new one. */
  function resetMainTimer(seconds) {
    changeFavicon("red");
    timer.pause();
    timer = new CountdownTimer(seconds);
    timer.onTick(setTimeOnAllDisplays);
  }

  // Set default page timer displays
  setTimeOnAllDisplays(timeObj.minutes, timeObj.seconds);

  timer.onTick(setTimeOnAllDisplays);

  // Add listeners to buttons
  document.getElementById("btn_ctrl").addEventListener("click", function () {
    timer.control();
  });

  document
    .getElementById("btn_pomodoro")
    .addEventListener("click", function () {
      resetMainTimer(25 * 60);
      timer.start();
    });

  document
    .getElementById("btn_shortbreak")
    .addEventListener("click", function () {
      resetMainTimer(5 * 60);
      timer.start();
    });

  document
    .getElementById("btn_longbreak")
    .addEventListener("click", function () {
      resetMainTimer(15 * 60);
      timer.start();
    });

  document.getElementById("btn_custom").addEventListener("click", function () {
    let customUnits = document.getElementById("custom_units").value;
    if (customUnits === "minutes") {
      resetMainTimer(customTimeInput.value * 60);
    } else if (customUnits === "hours") {
      resetMainTimer(customTimeInput.value * 3600);
    } else {
      resetMainTimer(customTimeInput.value);
    }
    timer.start();
  });

  document.body.addEventListener("keyup", (event) => {
    let key = event.keyCode || event.which;
    let keychar = String.fromCharCode(key);
    if (key == 32 || keychar == " ") {
      timer.control();
      event.preventDefault();
    }
  });
};
